import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/layout/Layout";
import { ContactForm } from "../components/themed/contactForm";
import GrupoAerotecLogos from "../components/themed/grupoAerotecLogos";
import { Banner } from "../components/themed/promocionesPage/Banner";
import { VendedoresButtons } from "../components/themed/VendedoresButtons";

const PromocionesPage = ({
  data,
}: PageProps<{ promociones: Queries.PromocionesJsonConnection }>) => {
  return (
    <Layout>
      <Banner />
      <VendedoresButtons />
      <div className="container-p my-10">
        <h1 className="text-center font-bold text-aerotec-dark-20 text-4xl mb-4 md:text-5xl lg:text-6xl">
          Promociones Vigentes
        </h1>
        <p className="text-center text-lg text-gray-800 lg:text-xl">
          Aerotec Publicidad te ofrece promociones y cupones. Somos una empresa
          dedicada al diseño y fabricación de material publicitario dirigido a
          grandes empresas y pequeños negocios, nuestros productos más populares
          son Sky Dancers, Banderas, Carpas y más. Navega en nuestro sitio web
          para conocer más.
        </p>
        <br />
        <p className="text-center text-lg text-gray-800 lg:text-xl">
          Comunicate con nosotros y te haremos llegar la información que deseas
          sobre las promociones y cupones que aerotec publicidad tienen para ti.
          Siempre tenemos promociones que se acomodan a tu presupuesto para
          darle la mejor imagen a tu negocio o marca.
        </p>
        <br />
        <div className="promos mt-6 grid grid-cols-1 md:grid-cols-2 gap-y-8 my-10">
          {data.promociones.edges.map(({ node: promo }) => (
            <div
              key={promo.id}
              className=""
            >
              <GatsbyImage
                alt={promo.alternativo || "Promoción"}
                image={promo.imagen?.childImageSharp?.gatsbyImageData!}
                className="rounded-lg w-full sm:w-11/12 mx-auto block"
              />
            </div>
          ))}
        </div>
        <div className="my-10 md:w-10/12 md:mx-auto">
          <div>
            <h2 className="text-aerotec-dark-15 text-center font-bold text-4xl mb-4 md:text-5xl">
              ¡Ponte en contacto con nosotros!
            </h2>
            <p className="text-lg md:text-xl text-center">
              Comunicate con nosotros y te haremos llegar la información que
              necesites.
            </p>
          </div>
          <ContactForm />
        </div>
        <br />
        <GrupoAerotecLogos />
      </div>
    </Layout>
  );
};

export default PromocionesPage;

export const query = graphql`
  query {
    promociones: allPromocionesJson {
      edges {
        node {
          imagen {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
          id
        }
      }
    }
  }
`;
