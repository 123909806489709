import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const Banner = () => {
  const data = useStaticQuery<{
    image: Queries.FileConnection;
  }>(graphql`
    query {
      image: allFile(
        filter: {
          relativeDirectory: { eq: "promociones" }
          base: { eq: "banner.jpg" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  return (
    <div className="w-full h-auto">
      <GatsbyImage
        alt="Banner promociones Aerotec"
        image={data.image.edges[0].node.childImageSharp?.gatsbyImageData!}
      />
    </div>
  );
};
